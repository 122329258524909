import React from 'react'

import { ImageSharp } from '../../types'
import Image from '../image'

export interface TourLodgingData {
  description: string[]
  images: {
    src: ImageSharp
    heading: string
    subHeading: string
  }[]
  video?: {
    url?: string
    caption?: string
  }
}

interface TourLodgingProps {
  data: TourLodgingData
}

const TourLodging = ({
  data: { description, images, video },
}: TourLodgingProps) => (
  <div className="row">
    <div className="col-md-6">
      {/* <strong className="header-box">{heading}</strong> */}
      <div className="detail">
        {description.map((paragraph, j) => (
          <p key={j} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      </div>
      {!!video?.url && (
        <>
          <iframe
            width="560"
            height="315"
            src={video.url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{ marginTop: '20px' }}
          ></iframe>
          {!!video?.caption && (
            <p style={{ marginTop: '10px' }}>{video.caption}</p>
          )}
        </>
      )}
    </div>
    <div className="col-md-6 accomodation-block">
      {images.map(({ src, heading, subHeading }, i) => (
        <div key={i} className="text-box">
          <div className="holder">
            {heading && (
              <strong className={`title${subHeading ? '' : ' smaller'}`}>
                {heading}
              </strong>
            )}
            {subHeading && <span className="sub-title">{subHeading}</span>}
            {src && (
              <div className="img-holder">
                <Image image={src} alt={heading} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default TourLodging
